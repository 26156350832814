.progress-tracker {
  padding: 60px;
  border-radius: 4px;
  background-color: #fff;
}

.progress-tracker .panel {
  border: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
  padding-bottom: 20px;
  background-color: transparent;
  border-bottom: 1px solid #ddd;
}

.progress-tracker .accordion-header button {
  border: 0;
  padding: 0;
  width: 100%;
  color: #333;
  cursor: pointer;
  padding-bottom: 70px;
  display: inline-block;
}

.progress-tracker .accordion-collapse {
  border: 0;
}

.status-for h5,
.status-for h6 {
  margin: 0;
}

.status-for h5 {
  font-size: 20px;
  line-height: 30px;
  margin-right: 15px;
  font-family: "Montserrat";
}

.rtl .status-for h5 {
  margin-right: 0;
  margin-left: 15px;
  text-align: right;
}

.rtl .car-progress .status-for {
  text-align: right;
}

.rtl .car-progress .status-for span.fa::after {
  margin-top: 9px;
}

.car-progress .col-md-1 {
  margin-top: 15px;
}

.car-progress .col-md-3 {
  transform: translateY(35px);
}

.status-for h6 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: "Montserrat";
}

.status-for.details a {
  padding: 8px 15px;
  margin: 0 !important;
}

.car-progress .status-for .more-details {
  font-size: 14px;
  cursor: pointer;
  color: #05c3de;
  margin-top: 10px;
  display: inline-block;
  font-family: "Montserrat";
}
.rtl .car-progress .status-for .more-details {
  font-size: 18px;
}
.car-progress button .status-for span.fa::after {
  color: #05c3de;
  content: "\f078";
  margin: 0px 5px;
  cursor: pointer;
}
.car-progress button:not(.collapsed) .status-for span.fa::after {
  content: "\f077";
}
.chart-of-progress {
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 20px;
  border-radius: 8px;
  align-items: center;
  margin: 0 0 30px 15px;
  max-width: fit-content;
  background-color: #f8f8f8;
}
.chart-of-progress p {
  font-size: 14px;
  margin: 0 15px 0 0;
}
.rtl .chart-of-progress p {
  margin: 0 0 0 15px;
}
.chart-of-progress p i {
  margin: 0 8px 0 0;
}
.rtl .chart-of-progress p i {
  margin: 0 0 0 8px;
}
.chart-of-progress .inProgress i {
  color: #ffb71b;
}
.chart-of-progress .on-pending i {
  color: lightblue;
  color: lightblue;
}
.chart-of-progress .completed i {
  color: #23c275;
}
.step {
  font-size: 16px;
}
.step.progress {
  width: 3em;
  height: 3em;
  overflow: visible;
  /* font-size: 1em !important; */
  background-color: #ffb71b !important;
}

.step .content {
  text-align: center;
  min-width: 75px !important;
  transform: translateX(-12px);
}

.step.progress .content {
  margin-top: 18px;
}

.step .content.last {
  transform: translateX(18px);
}

body.rtl .step .content {
  min-width: auto;
}

.progress-tracker .progress-bar,
.progress-tracker .progress-bar::after,
.progress-tracker .pending {
  background-color: lightblue;
}

.progress-tracker .progress-bar.completed,
.progress-tracker .progress-bar.completed::after {
  background-color: #23c275 !important;
}

.detail-body {
  padding-left: 0;
  transform: scale(0.8) translateX(-35px);
}

body.rtl .detail-body {
  transform: scale(0.8) translateX(35px);
}

.progress-tracker p.title {
  margin-top: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

.rtl .progress-tracker p.title {
  font-size: 16px;
}

.progress-tracker .stepper > div {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-tracker .stepper > div:last-child {
  border: 1px dashed #e9e9e9;
}

.progress-tracker .estimation-canceled .stepper > div:last-child {
  background-color: #dc3545;
}

.per-car-progress {
  padding: 0 30px;
}

.per-car-progress > .row {
  display: flex;
  align-items: center;
}

.per-car-progress .status-for {
  display: flex;
  align-items: center;
}

.progress-tracker .current-step {
  width: 76%;
  height: 74%;
  border-radius: 100%;
  border: 4px solid #fff;
}

.progress-tracker .no-data {
  color: #ccc;
  font-size: 22px;
  text-align: center;
}

.progress-tracker .estimated-date {
  color: #303e49;
  font-size: 14px;
  text-align: right;
  margin-bottom: 8px;
  font-family: "Montserrat";
  transform: translateX(22px);
}

.rtl .progress-tracker .estimated-date {
  font-size: 18px;
  text-align: left;
  transform: translateX(-22px);
}

.progress-tracker .estimated-date span {
  color: #666;
}

.progress-tracker .estimated-msg {
  font-size: 14px;
  color: #05c3de;
  font-weight: 600;
  text-align: right;
  margin: 0 -15px 0 0;
  font-family: "Montserrat";
}

.progress-tracker .estimated-msg.collapsed {
  padding-bottom: 85px;
  transform: translateY(75px);
}

.rtl .progress-tracker .estimated-msg {
  text-align: left;
}

.progress-tracker .estimated-msg span {
  color: #303e49;
  font-weight: 500;
}

.progress-tracker .tab-content {
  padding: 0 18px;
}

.progress-tracker .tab-content {
  padding: 0 15px;
}

.track-header {
  padding-top: 40px;
}

.track-header h2 {
  font-weight: 900;
  text-align: center;
}

.track-header .phone {
  display: flex;
  margin-top: 40px;
  align-items: center;
}

.track-header .phone .form-group {
  background-color: #fff;
}

.track-header .phone input {
  width: 250px;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: #ced4da;
}

.rtl .track-header .phone input {
  border-width: 0 1px 0 0;
}

.request-estimate-body .expired-label {
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 15px 0 0;
  padding: 5px 10px;
  line-height: 16px;
  border-radius: 4px;
  max-width: fit-content;
  background-color: #dc3545;
}

/* .fast-collect-controls {
  display: flex;
  align-items: center;
  flex-direction: row;
} */

.fast-collect-controls button {
  margin: 0 10px 20px 5px;
  padding: 6px 12px !important;
  display: inline !important;
}
.recommendationMedia {
  display: flex;
  flex-wrap: wrap;
}
.recommendation-offer-meta-data {
  flex-direction: row;
  align-items: start !important;
}
.eye-icon-wrapper {
  color: #05c3de;
  font-size: 20px;
}
.recommendationOfferCard h1 {
  margin-bottom: 10px;
}
.media-wrapper {
  margin: 30px 0;
}
.active-wrapper label > input {
  margin-left: 15px;
}
.active-wrapper .save-btn-wrapper {
  text-align: right;
}
.recommendationOfferCard span {
  margin-bottom: 20px;
}
.add-car-modal-footer {
  display: flex;
  justify-content: space-between;
}
.add-car-modal-footer .complete-repair input {
  margin-right: 5px;
}
.reason-history {
  color: #05c3de;
  text-decoration: underline;
  cursor: pointer;
}
.reasons-modal-wrapper label {
  font-size: 14px;
}
.reasons-modal-wrapper p {
  font-size: 18px;
}
.upsell-recommmendation-form .media img,
.upsell-recommmendation-form .media video {
  width: 48%;
  height: 100%;
  cursor: pointer;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 5px;
}

/* Tabs Styling Start*/

.table-header .clearfix .track-tabs::before {
  content: "" !important;
}

.track-tabs .nav button:focus {
  outline: none;
  border: none;
}
.track-tabs .nav button {
  color: #333;
  cursor: pointer;
  background-color: #e9e9e9;
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;
  border: 0;
  margin: 15px;
  font-weight: 600;
}
.track-tabs .nav button {
  background-color: #e9e9e9 !important;
  border-color: #e9e9e9 !important;
}
.track-tabs .nav button.active {
  color: #05c3de !important;
  border-bottom: 2px solid #05c3de !important;
}
.track-tabs .nav button:hover,
.track-tabs .nav button.active:hover {
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.track-tabs .nav-tabs {
  display: flex;
  justify-content: center;
}

/* Tabs Styling End*/

/* Estimation Progress Start */

.request-estimate-body.estimate-head {
  position: relative;
  padding: 30px 0 65px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.request-estimate-body.estimation-approved {
  padding: 30px 0;
}

.notification-icon {
  display: flex;
  margin: 10px 0;
  justify-content: flex-end;
}

.notification-icon i {
  font-size: 28px;
  color: #05c3de;
  cursor: pointer;
}

.notification-icon .badge-icon {
  color: #000;
  font-size: 12px;
  min-width: 20px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 3px 7px 3px 8px;
  background-color: #ffb71b !important;
}

.estimation-booked:not(.estimation-expired) .estimation-data {
  margin: 15px 0 !important;
}
.request-estimate-body .estimation-data.desktop {
  text-align: right;
}
.rtl .request-estimate-body .estimation-data.desktop {
  text-align: left;
}

.estimation-data {
  display: grid;
}
.estimation-data .pdf-link button {
  border: none;
  background-color: transparent;
  color: #05c3de !important;
  text-decoration: underline !important;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  margin-top: 5px;
}

/* Estimation Progress End */

/* Close reason modal */
.view-reason {
  border: none;
  background-color: transparent;
  color: #05c3de;
  text-decoration: underline;
  margin: 15px 0 0;
  padding: 0;
}
.image-guidance-modal-body.reason-modal-body {
  padding: 30px;
}
.reason-modal-body h6 {
  font-weight: 600;
  text-align: center;
  margin: 10px 0 20px;
}
.reason-modal-header h4 {
  font-size: 24px;
  font-weight: 700;
  color: #05c3de;
  text-align: center;
}
.rtl .reason-modal-header h4 {
  text-align: right;
}

.image-guidance-modal-body {
  padding: 30px 50px 30px;
}
.image-guide-modal-header {
  width: 100%;
  text-align: center;
  margin: 0 0 30px 0;
}
.image-guide-modal-header h4 {
  font-size: 28px;
  font-weight: 700;
  color: #05c3de;
}
.image-guide-modal-header p {
  max-width: 580px;
  line-height: 22px;
  margin: 0 auto 35px;
}
.image-guidance-wrapper .carWrapper h6 {
  margin: 0;
  font-weight: 600;
}
.image-guidance-wrapper .carWrapper {
  padding: 20px;
  margin-top: 10px;
  text-align: center;
  border-radius: 25px;
  border: 1px solid #05c3de;
}
.image-guidance-wrapper .carWrapper {
  height: 100%;
}
.image-guidance-wrapper .carWrapper img {
  margin-top: 20px;
  max-width: 100%;
}
.image-viewer .close-icon i,
.order-detail-modal .user-detail-icon i {
  width: 18px;
  height: 18px;
  padding: 4px;
  display: flex;
  font-size: 18px;
  cursor: pointer;
  font-weight: 200;
  transition: 0.3s;
  text-align: center;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  border: 1px solid #000;
}

.comments-modal .image-guide-modal-header {
  margin-bottom: 25px;
}
.comments-modal .image-guide-modal-header p {
  margin-bottom: 30px;
}
.comments-modal .image-guide-modal-header h6 {
  font-weight: 600;
  margin-top: 10px;
}
.notification-modal .no-comments {
  color: #ccc;
  text-align: center;
  margin: 90px 0px;
}
.admin-message {
  margin-top: 15px;
  border-radius: 4px;
}
.border-blue {
  border: 1px solid #05c3de;
}
.border-gray {
  border: 1px solid #ddd;
}
.notification-modal .notifications {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  justify-content: space-between;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.notification-modal .read-notification {
  background-color: #f2f2f2;
}
.notification-modal .unread-notification {
  color: #fff;
  background-color: #05c3de;
}
.notification-modal .msg-body {
  padding: 10px;
  font-size: 14px;
}

.estimation-btn {
  margin-top: 10px;
}
.estimation-btn span {
  color: red;
  font-size: 18px;
  font-weight: 600;
}
.images-wrapper-repair-progress {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
}
.images-wrapper-repair-progress img {
  max-height: 200px;
}

.form-group {
  border: 1px solid #ced4da;
  /* padding: 5px; */
  border-radius: 6px;
  width: auto;
  margin-bottom: 0;
}
.form-group:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.form-group input {
  display: inline-block;
  width: auto;
  border: none;
}
.form-group input:focus {
  box-shadow: none;
}
