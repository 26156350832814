.row.condensed {
  margin-left: -5px;
  margin-right: -5px;
}

.row.condensed div[class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.flexible {
  display: flex;
  align-content: center;
  align-items: center;
}
.tooltiptext {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#Submenu {
  margin-left: 20px;
}

img.thumbnail {
  margin-top: 10px;
}

tbody td {
  vertical-align: middle !important;
}

.dashboard-row.label-row {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-row.label-row > div {
  flex: 20% 0;
  margin-bottom: 26px;
}

.dashboard-row.label-row > div:last-child {
  flex: 100%;
}

.dashboard-row.label-row > div strong {
  display: block;
  margin-bottom: 3px;
}

.table.card-layout {
  width: auto;
  max-width: initial;
}
.my-frame {
  height: 800px;
  border: none;
  scroll-behavior: unset !important;
  overflow: hidden;
}
.DateRangePicker_picker {
  z-index: 10 !important;
}

.rtl .navbar.navbar-fixed-top {
  right: initial;
  left: 0;
}

.rtl .btn-special .fa {
  margin-left: 6px;
  position: relative;
  top: 3px;
}

.rtl .fileChooseBtn {
  margin-right: 0px !important;
  margin-left: 10px !important;
}

/******** RTL Layout *******/

.rtl .DateRangePicker_picker {
  left: initial !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid #29c0dc !important;
}

.CalendarDay__selected_span {
  background: #76c5f1 !important;
  border: 1px double #76c5f1 !important;
  color: #fff;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #29c0dc !important;
  border: 1px double #29c0dc !important;
  color: #fff;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  border-top: 26px solid transparent;
  border-right: 33px solid #29c0dc !important;
  bottom: 0;
  right: 0;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #21a2ea !important;
  border: 1px double #21a2ea !important;
  color: #fff;
}

.DateInput_input__focused {
  border-bottom: 2px solid #29c0dc !important;
}

.sidebar-link .fa {
  float: left;
  position: absolute;
  top: calc(50% - 8px);
}

.sidebar-link .list-group-link {
  padding-left: 30px;
  float: left;
  width: fit-content;
}

input[name="showPassword"] {
  margin-right: 6px;
  margin-top: 0px;
}

.rtl .navbar-collapse {
  float: left !important;
}

.rtl .sidebar-link .list-group-link {
  float: right;
  padding-left: 0;
  padding-right: 40px;
}

@media (max-width: 767px) {
  hr.breaker {
    width: 100%;
    border: 1px dashed #d6d6d6;
    margin: 28px 15px;
    overflow: visible;
  }

  .breaker-header {
    color: #000;
    width: 100%;
    font-size: 14px;
    margin: 10px 15px;
  }
  hr.breaker + .breaker-header {
    margin-top: 0px;
  }
  .addon-services > div {
    width: 100% !important;
    flex: 100% !important;
  }
  .dashboard-row {
    padding: 21px;
  }

  .rtl .panel-title {
    text-align: right;
  }
  .rtl .form-label {
    text-align: right;
  }
  .rtl .dashboard-table-container {
    direction: ltr;
  }
  body {
    overflow: visible;
  }
  .right {
    min-height: 100%;
    overflow-y: scroll;
  }
  #content,
  html,
  body,
  .dashboard-main {
    position: static;
  }
  .dashboard-main.active {
    left: initial;
    right: initial;
    -webkit-transform: translateX(240px);
    -ms-transform: translateX(240px);
    transform: translateX(240px);
  }
  .rtl .dashboard-main.active {
    -webkit-transform: translateX(-240px);
    -ms-transform: translateX(-240px);
    transform: translateX(-240px);
  }
}
ul#Submenu {
  padding-left: 0;
}
.list-group-item {
  padding: 15px 20px !important;
}
.dropdown_arrow {
  float: right;
  margin-right: 0 !important;
}

.OrderFilter .has-feedback {
  position: relative;
  margin-bottom: 0;
}
.OrderFilter .has-feedback .form-control {
  margin-bottom: 0;
}

.serviceFilter .has-feedback {
  position: relative;
  margin-bottom: 0;
}
.serviceFilter .has-feedback .form-control {
  margin-bottom: 0;
}
.navbar.navbar-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: initial;
  width: calc(100% - 240px);
  z-index: 9999;
  padding-left: 25px;
  padding-right: 25px;
  height: 70px !important;
}

thead.fixed-header {
  position: fixed;
  top: 104px;
  z-index: 99;
}

.floatThead-table {
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

span.backface {
  display: none;
  margin-left: -20px;
  width: 20px !important;
  height: 70px;
  background-color: #fff;
}

span.backface-2 {
  display: none;
  left: 20px !important;
  height: 70px;
  background-color: #fff;
}

/* .table-responsive {
  z-index: 999;
} */

.grid--fullht #tableContainer {
  overflow-x: auto;
  flex: none !important;
  margin-bottom: 30px !important;
}

@media (max-width: 767px) {
  .navbar.navbar-fixed-top {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .dashboard-main {
    padding-top: 80px;
  }
  .rtl .navbar.navbar-fixed-top {
    width: 100% !important;
  }
}
.rtl .addon-services .card {
  font-size: 20px !important;
}

.rtl .addon-services .card * {
  line-height: 20px;
}

.rtl .container-fluid > div {
  float: right;
}

.addon-services .card .flexible {
  width: 100%;
}
.profile-photo {
  margin-top: 10px !important;
}
.SingleDatePicker_picker {
  z-index: 99999 !important;
}
.table-header .btn:focus {
  outline: none;
}