.login-row-wrapper:nth-last-child(-n + 2) {
  margin: auto;
  width: 55% !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.service-advisor-login-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* LOGIN RIGHT BOX STYLING */
.en-bg-sky-white,
.ar-bg-sky-white {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.en-bg-sky-white {
  background-image: linear-gradient(
    to left,
    #ffffff,
    #ffffff 50%,
    #29c0dc 50%,
    #29c0dc
  ) !important;
}
.ar-bg-sky-white {
  background-image: linear-gradient(
    to left,
    #29c0dc,
    #29c0dc 50%,
    #ffffff 50%,
    #ffffff
  ) !important;
}
.autohub-logo-wrapper {
  text-align: center;
  padding-bottom: 20px;
}
.autohub-logo-wrapper img {
  width: 200px;
  margin-bottom: 15px;
}
.login-row-wrapper .service-advisor-login-field {
  width: 85%;
  padding: 17px;
  border-radius: 30px;
  background-color: #f8f8f8 !important;
  border: 1px solid #f8f8f8;
  outline: none;
}
.login-row-wrapper .text-warning {
  width: 80%;
  text-align: start;
  margin: 5px auto 0;
}
.login-row-wrapper .arabic-field {
  direction: ltr !important;
}
.login-row-wrapper .service-advisor-login-field:focus-visible {
  border-color: #29c0dc;
}
.login-row-wrapper .full-row:not(.login-btn-wrapper) {
  margin-bottom: 20px;
}
.service-advisor-login-wrapper .login-left-box {
  width: 50%;
  text-align: center;
}
.service-advisor-login-wrapper .right-form-box {
  width: 50%;
  text-align: center;
}

.login-row-wrapper .eye-icon-wrappr a {
  background-color: transparent;
  border: 0;
  margin: 5px auto;
}
.login-row-wrapper .login-left-box h3 {
  color: #ffff;
  font-weight: 800;
  font-size: 36px;
  /* margin-top: 35px; */
}
.service-advisor-pass-field {
  position: relative;
}
.eye-icon-wrapper {
  position: absolute;
  right: 12%;
}
.eye-icon-wrapper a {
  background-color: transparent !important;
  border: none;
  margin: 12px auto;
}
.login-row-wrapper .login-btn-wrapper input[type="submit"] {
  padding: 10px 50px;
  border-radius: 30px;
}
.login-row-wrapper form {
  padding: 30px 0;
}
.lang-wrapper a {
  color: #fff;
}
.lang-wrapper span {
  font-size: 30px !important;
  margin: 10px;
}
#verifyUser,
.rtl #verifyUser {
  padding: 25px 20px 15px 15px;
}
#verifyUser .box input[type="number"] {
  outline: none;
  padding: 10px 0;
  border: 1px solid #ced4da;
}
.OTP {
  display: flex;
  padding: 20px 0 10px 0;
}
.OTP .field-box-wrapper {
  margin-right: 20px;
}
.otp-title-wrapper h6 {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 7px;
}
.otp-event-btns .back-btn {
  background-color: #29c0dc;
}
.otp-event-btns .verify-btn {
  background-color: #29c0dc61;
}
.otp-event-btns .dark-blue {
  background-color: #29c0dc;
}
.otp-event-btns .back-btn,
.otp-event-btns .verify-btn {
  color: #fff;
  outline: none;
  border: none;
  padding: 8px 30px;
  border-radius: 30px;
  margin: 10px 10px 0 0;
}
.field-box-wrapper .box .red-border {
  border-color: red !important;
}
.error-wrapper {
  width: 84%;
  margin: auto;
}
.error-wrapper-ar p {
  text-align: right;
}
.otp-input-box .red-color > span {
  color: red !important;
}
.otp-input-box .OTP {
  direction: ltr !important;
}
