.service-status-wrapper .btn-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.service-status-wrapper .btn-box :first-child {
  margin: 0 10px 0 0;
}
.rtl .service-status-wrapper .btn-box :first-child {
  margin: 0 0 0 10px;
}
.service-status-fields-wrapper label {
  padding-top: 6px;
  margin-bottom: 7px;
  font-size: 15px;
  font-weight: 300;
  text-transform: uppercase;
  color: #757575;
}
.service-status-fields-wrapper input[type="text"],
.service-status-fields-wrapper select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #808080;
  background-color: #efefef;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
.service-status-fields-wrapper input[type="checkbox"] {
  width: auto !important;
  }
.service-status-fields-wrapper .sub-options-head > label {
  margin: 0;
  padding: 0;
}
.service-status-fields-wrapper .sub-options-head label span {
  font-weight: 600;
  font-size: 18px;
}
.service-status-fields-wrapper .sub-options-wrapper {
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.service-status-fields-wrapper .sub-options-wrapper .dashboard-row {
  padding: 15px 0;
}
.sub-options-wrapper .btn-box {
  justify-content: flex-start;
}
.btn-box .sub-options-remove-btn {
  margin-bottom: 10px;
}
